<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2"> Welcome 👋 </b-card-title>
          <b-card-text class="mb-2"> Please sign-in to your account </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="User Name" vid="email">
                  <b-form-input id="login-email" v-model="userName" :state="errors.length > 0 ? false : null" name="login-email" placeholder="john@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1"> Remember Me </b-form-checkbox>
              </b-form-group>
              <b-link @click="forgotPasswordEmail">
                <b-spinner v-if="passwordloading" small style="margin-bottom: 3px" />
                <small>Forgot Password?</small>
              </b-link>
              <b-alert variant="danger" :show="invalidPasswordReset" dismissible>Please enter your username to reset your password</b-alert>
              <b-alert variant="success" :show="resetSuccess" dismissible
                ><h4 class="alert-heading">Please check your email for instructions on resetting your password.</h4></b-alert
              >
              <!-- submit buttons -->
              <b-button type="submit" variant="primary" block :disabled="invalid || loading">
                <b-spinner v-if="loading" small style="margin-bottom: 3px" />
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { mapActions, mapGetters } from "vuex";
import { forgotPassword } from "@/service/PasswordSvc.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { AuthActionTypes } from "@/store/auth";

export default {
  directives: {
    "b-tooltip": VBTooltip
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: null,
      userName: null,
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      resetSuccess: false,
      // validation rules
      required,
      email,
      invalidPasswordReset: false,
      loading: false,
      passwordloading: false
    };
  },
  computed: {
    ...mapGetters({
      getUserCreds: "getAuthUserCredentials"
    }),
    appName() {
      const hn = window.location.hostname;
      if (hn && hn.toLowerCase().includes("sweetleaf")) {
        return "The Sweetleaf Veteran and Patient Network";
      }
      return "Listo Admin";
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      //
      const hn = window.location.hostname;
      if (hn && hn.toLowerCase().includes("sweetleaf")) {
        this.sideImg = require("@/assets/images/logo/sweetleaf-listo.png");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  methods: {
    ...mapActions({
      saveLoginDetails: AuthActionTypes.LOGIN_SUCCESS
    }),
    async forgotPasswordEmail() {
      if (!this.userName) {
        this.invalidPasswordReset = true;
        return;
      }
      this.invalidPasswordReset = false;
      let d = {};
      d.userName = this.userName;
      //forgotPassword;
      this.passwordloading = true;
      await forgotPassword(d);
      this.resetSuccess = true;
      this.passwordloading = false;
    },
    login() {
      const hn = window.location.hostname;
      const tn = hn + "-deviceId";
      let devId = localStorage.getItem(tn);
      if (!devId) {
        devId = "";
        var m = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
        var i = 0;
        var rb = (Math.random() * 0xffffffff) | 0;
        while (i++ < 36) {
          var c = m[i - 1];
          var r = rb & 0xf;
          var v = c == "x" ? r : (r & 0x3) | 0x8;
          devId += c == "-" || c == "4" ? c : v.toString(16);
          rb = i % 8 == 0 ? (Math.random() * 0xffffffff) | 0 : rb >> 4;
        }

        localStorage.setItem(tn, devId);
      }
      axiosIns.defaults.headers.common["x-device-id"] = devId;
      axiosIns.defaults.headers.common["x-screen-size"] = "(" + window.innerWidth + ", " + window.innerHeight + ")";
      axiosIns.defaults.headers.common["x-device-type"] = "Desktop";

      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          useJwt
            .login({
              userName: this.userName,
              password: this.password
            })
            .then((response) => {
              this.loading = false;
              const userData = response.data;
              const creds = {
                authUser: response.data,
                userPass: this.password,
                bearerToken: response.data.bearer_token
              };
              this.saveLoginDetails(creds);
              // localStorage.setItem(useJwt.jwtConfig.storageTokenKeyName, response.data.bearer_token)
              let noab = JSON.parse(JSON.stringify(userData));
              //console.log(noab.ability);
              noab.ability = [];
              localStorage.setItem("userData", JSON.stringify(noab));

              // defined in ability.js. Possible values that are being used can be found at @fake-db/jwt/index.js
              this.$ability.update(userData.ability);

              const nr = getHomeRouteForLoggedInUser(userData);
              console.log("Redirect to: ", nr);
              this.$router.replace(nr).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Welcome ${userData.fullName || userData.username}`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: "Login success!"
                  }
                });
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$refs.loginForm.setErrors(error.response.data.error);
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.app-title-margin {
  margin-top: 10px;
}
</style>
