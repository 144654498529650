import useJwt from "@core/auth/jwt/useJwt";
import axios from "@axios";

const overrideConfig = {
  // Endpoints
  loginEndpoint: "/login",
  registerEndpoint: "/register",
  refreshEndpoint: "/refreshtoken",
  logoutEndpoint: "/logout",

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",

  storageBearerTokenName: "bearer_token"
};

const { jwt } = useJwt(axios, overrideConfig);
export default jwt;
